import { MiddlewareAPI, Dispatch, AnyAction, Middleware } from 'redux';
import { PREFIXED_WEBSOCKET_MESSAGE, stage, stageData, _hangUp, chatMessages, PREFIXED_WEBSOCKET_OPEN, processChatAuth, ping, pong, partial, busy, hangUp, START_PINGING, pinger, latestInfo, processAttachments, setCandidateInfo, setOfferInfo } from '../actions/call-actions';
import { IWebsocketInMessage, InMessageType } from '../models/websocket-in-message';
import { UserRole } from '../models/user-role.enum';
import { Owner, IChatMessage, IChatAttachment } from '../models/chat-message';
import { RootState } from '../store';
import { ITextMessage, TextMessageType, ICallData, IResponseData } from '../models/text-message';
import { CallStage } from '../models/call-stage';
import { authFetch, lastToken, signOut } from '../actions/auth-actions';
import { CallSubStage } from '../models/call-sub-stage';
import { send, disconnect, connect } from '@giantmachines/redux-websocket';
import * as environment from '../app.json';
import { IWebsocketOutMessage, OutMessageType } from '../models/websocket-out-message';
import { ICandidateInfo, ISdpInfo, IWebsocketState } from '../states/websocket-state';
import { IPartial } from '../models/partiial';
import { ICallState } from '../states/call-state';
import { CallStatus } from '../models/call-history';
import { IUserToken } from '../models/user-token';

export default function chatMessageMiddleware(
    config: {} = {}
): Middleware<any, any, any> {
    return (store: MiddlewareAPI<any, RootState>) => (next: Dispatch<AnyAction>) => (
        action: AnyAction
    ) => {
        if (action.type === PREFIXED_WEBSOCKET_MESSAGE) {

            if (action.payload.message === "<") {
                store.dispatch(pong());
                store.dispatch(pinger(setTimeout(() => pingPong(store), 10000)));
                console.log("pingPong");
                return;
            }

            const payload = JSON.parse(action.payload.message) as IWebsocketInMessage;

            const auth = store.getState().auth;
            processChatMessage(payload, store.getState().websocket, store.getState().call, auth.userToken?.selfId, store.dispatch, store.getState);
            return;
        };


        if (action.type === 'REDUX_WEBSOCKET::ERROR') {
            console.warn('REDUX_WEBSOCKET::ERROR', action);
        }

        if (action.type === START_PINGING) {
            store.dispatch(pinger(setTimeout(() => pingPong(store), 5000)));
        }

        if (action.type === PREFIXED_WEBSOCKET_OPEN) {
            const auth = store.getState().auth;
            if (!auth.userToken) {
                store.dispatch(disconnect());
                return;
            }
            store.dispatch(processChatAuth());
            store.dispatch(pinger(setTimeout(() => pingPong(store), 5000)));
        }

        return next(action);
    };
}

const pingPong = (store: MiddlewareAPI<any, RootState>) => {
    console.log(">", store.getState().auth.userToken?.auth);
    if (!store.getState().auth.userToken?.auth) {
        store.dispatch(disconnect());
        return;
    }
    const reconnect = (b: boolean) => setTimeout(() => {
        console.log("reconnect!", !b && store.getState().websocket.online);
        if (!b && store.getState().websocket.online) {
            return;
        }
        console.log("disconnect!");

        store.dispatch(disconnect());
        const auth = store.getState().auth;
        if (!auth.userToken?.auth) {
            return;
        }
        console.warn("connect!", environment.wsHost);
        store.dispatch(connect(environment.wsHost));
        setTimeout(() => {
            reconnect(false);
        }, 2000);
    }, 5000);
    console.log("ping(reconnect(true)))");
    store.dispatch(ping(reconnect(true)));
    store.dispatch(send(">"));
}

const processChatMessage = async (payload: IWebsocketInMessage, state: IWebsocketState, callState: ICallState, selfId: number | undefined, dispatch: Dispatch<any>, getState: () => RootState) => {
    const chatUserId = state.chatUserId;
    if (payload.type === InMessageType.Forbidden) {
        dispatch(signOut(''));
    } else if (payload.type === InMessageType.Message) {
        const messages = payload.messages;
        if (!messages || messages.length === 0) {
            return;
        }
        const msgs: IChatMessage[] = [];
        const lastDate = state.lastDate;
        for (const message of messages) {
            let self = false;

            if (selfId === message.doctorId) {
                if (message.owner === Owner.Doctor) {
                    self = true;
                }
            } else if (selfId === message.patientId) {
                if (message.owner === Owner.Patient) {
                    self = true;
                }
            }

            console.log("message1111", message);

            const ago = Math.abs(new Date((message.current as any) + 'Z') as any - (new Date(message.created as any) as any));
            console.log("ago " + (ago / 60000));

            const text = JSON.parse(message.message) as ITextMessage;
            message.textMessage = text;

            let lastCallMessage = state.lastCallMessage;

            // @ts-ignore
            const peerConnection = callState.connectionData.peerConnection;
            console.log('peerConnection_chat_mw', JSON.stringify(peerConnection))

            switch (text.type) {
                case TextMessageType.Busy:
                    if (ago > 60000 || message.id === lastCallMessage) {
                        break;
                    }
                    const callUuid = text.data as string;
                    lastCallMessage = message.id;
                    if (self) {
                        if (callState.stage === CallStage.Income && callUuid === callState.stageData?.callUuid) {
                            console.log('close Busy', callState);
                            dispatch(_hangUp());
                            // if (useSimpleUI) {
                            //     IncomingCall.dismiss();
                            //     break;
                            // }
                            // RNCallKeep.endCall(callUuid);
                        }
                        break;
                    }
                    console.log('busy ' + callState.stageData?.callUuid, callUuid);

                    dispatch(hangUp(undefined));
                    break;
                case TextMessageType.Call:
                    if (self || ago > 60000 || message.id === lastCallMessage) {
                        break;
                    }
                    lastCallMessage = message.id;
                    const callData = text.data as ICallData;
                    if (callState.stageData?.callUuid && message.id !== callState.stageData?.callUuid) {
                        console.log('secondcall1', callState);
                        dispatch(busy(CallStatus.Rejected, callData.record, message.id));
                        break;
                    }
                    dispatch(stage(CallStage.Income, { callUuid: message.id, peerUuid: callData.patientUuid, peerUuid2: null, record: callData.record, started: new Date(ago + Date.now()) }));
                    break;
                case TextMessageType.Response:
                    if (self || ago > 60000 || message.id === lastCallMessage) {
                        break;
                    }
                    lastCallMessage = message.id;
                    const responseData = text.data as IResponseData;
                    if (self) {
                        if (callState.stage === CallStage.Income && responseData.callUuid === callState.stageData?.callUuid) {
                            console.log('close: other device', callState);
                            dispatch(_hangUp());
                            break;
                        }
                        break;
                    }
                    // InCallManager.stopRingback();
                    // console.warn('text.data', text.data);
                    dispatch(stageData({ peerUuid: responseData.doctorUuid, response: false }, CallSubStage.OutcomeReady));
                    break;


                case TextMessageType.Text:
                    ///const msgAttachments: IChatAttachment[] = [];

                    if (chatUserId === message.patientId || chatUserId === message.doctorId) {
                        if (message.attachments && message.attachments.length > 0) {
                            await processAttachments(message, getState, dispatch);
                        }
                        msgs.push(message);
                    }

                // const owner = message.owner === Owner.Doctor ? message.doctorId : message.owner === Owner.Patient ? message.patientId : 0;
                // const userId = message.doctorId === selfId ? message.patientId : message.doctorId;

                // db(selfId).transaction((tx) => {
                //     tx.executeSql('SELECT * FROM Messages WHERE id = ?', [message.id], (tx, results) => {
                //         if(results.rows.length === 0){
                //             // console.warn("saving1");
                //             tx.executeSql('INSERT INTO Messages (id, selfId, owner, userId, timestamp, text, deleted, toServer) VALUES (?, ?, ?, ?, ?, ?, ?, ?);', 
                //             [message.id, selfId, owner, userId, message.timestamp, text.data, 0, 1]);
                //         }
                //         else {
                //             var len = results.rows.length;
                //             for (let i = 0; i < len; i++) {
                //                 let row = results.rows.item(i);
                //                 if(row.toServer <= 0) {
                //                     //console.warn("REPLACE");
                //                     tx.executeSql('REPLACE INTO Messages (id, selfId, owner, userId, timestamp, text, deleted, toServer) VALUES (?, ?, ?, ?, ?, ?, ?, ?);', 
                //                     [message.id, selfId, owner, userId, message.timestamp, text.data, 0, 1]);
                //                 }
                //             }
                //         }
                //         // console.warn("saved");

                //         if (!message.attachments) {
                //             return;
                //         }

                //         console.warn('message.attachments', message.attachments);
                //         tx.executeSql('DELETE FROM ChatImages WHERE messageId = ?', [message.id]);
                //         for (const attachment of message.attachments) {
                //             tx.executeSql(
                //                 'INSERT INTO ChatImages (link, messageId, galeryLink, ownerLink, contentType, key) VALUES (?, ?, ?, ?, ?, ?);', 
                //                 [message.doctorId === selfId ? attachment.linkDoctor : attachment.linkPatient, message.id, '', attachment.linkOwner, attachment.mimeType, key]);
                //         }
                //     });
                // });
                case TextMessageType.sdpInfo:
                    const sdpInfo: any = text;

                    console.log('sdpInfoo', sdpInfo)

                    if (sdpInfo.data.type === 'OFFER' &&
                        sdpInfo.data.patientUuid &&
                        sdpInfo.data.patientUuid !== selfId) {
                        console.log('sdpInfo_offer_message1', text.data);
                        dispatch(setOfferInfo(text.data));
                    }
                    else if (sdpInfo.data.type === 'ANSWER' &&
                        sdpInfo.data.doctorUuid &&
                        sdpInfo.data.doctorUuid !== selfId) {

                        console.log('sdpInfo_answer_message_aaa', text.data)

                        await peerConnection.setRemoteDescription(new RTCSessionDescription({
                            //@ts-ignore
                            type: 'answer',
                            //@ts-ignore
                            sdp: text.data.sdp
                        }));
                    }
                    break;
                case TextMessageType.Candidate:
                    const candidateInfo: any = text;

                    console.log('candidateInfooo', candidateInfo)

                    if (candidateInfo.data.type === 'OFFER' &&
                        candidateInfo.data.patientUuid &&
                        candidateInfo.data.patientUuid !== selfId) {

                        if (peerConnection) {
                            //@ts-ignore
                            peerConnection.addIceCandidate(new RTCIceCandidate(text.data.candidate));
                        } else {
                            dispatch(setCandidateInfo([...state.candidateInfo, text.data]));
                        }
                        console.log('candidateInfo_offer_message1', text.data);

                    }
                    else if (candidateInfo.data.type === 'ANSWER' &&
                        candidateInfo.data.doctorUuid &&
                        candidateInfo.data.doctorUuid !== selfId) {

                        console.log('candidateInfo_answer_message_bbb', text.data)

                        // @ts-ignore
                        peerConnection.addIceCandidate(new RTCIceCandidate(text.data.candidate))
                    }
                    break;
                default:
                    break;
            }

            dispatch(latestInfo(lastCallMessage, message.updated));
        }
        //console.warn("chatMessages", msgs);

        if (msgs.length > 0) {
            dispatch(chatMessages(msgs));
        }
    } else if (payload.type === InMessageType.Partial) {
        const pp = state.partials[payload.partialId!];
        const ss = pp.s.substring(pp.i, Math.min(pp.s.length, pp.i + 16536));
        pp.i += 16536;
        const p: IWebsocketOutMessage = {
            type: OutMessageType.Partial,
            partialPart: ss,
            partialEnd: pp.i >= pp.s.length,
            partialId: pp.k,
            authorization: (await lastToken(dispatch))?.auth || '',
            browser: true,
        };
        console.warn("total size", pp.i);
        if (p.partialEnd) {
            dispatch(partial(pp as IPartial, true));
        } else {
            dispatch(partial(pp as IPartial));
        }
        dispatch(send(p));
    }
}
