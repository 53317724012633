import { ISchedulingItemRecord } from "./scheduling-item-record";

export interface ITextMessage {
    data: string | ICallData | IResponseData | ICandidateData | IOfferData;
    type: TextMessageType;
}

export interface ICallData {
    patientUuid: string;
    record: ISchedulingItemRecord;
}
export interface IOfferData {
    type: string,
    sdp: string,
    callUuid: any,
    patientUuid: any,
}
export interface ICandidateData {
    type: string,
    candidate: any,
    callUuid: any,
}
export interface IResponseData {
    doctorUuid: string;
    callUuid: string;
}
export enum TextMessageType {
    Text = 0, Call = 1, Response = 2, Busy = 3, Candidate = 4, sdpInfo = 5
}