import { ActionCreator, Action } from "redux";
import { CallStage } from "../models/call-stage";
import { ICallStageData } from "../models/call-stage-data";
import { peerJs } from '../app.json';
import { ThunkAction } from "redux-thunk";
import { ConnectionState } from "../models/connetion-state";
import { IConnectionData } from "../models/connection-data";
import * as environment from '../app.json';
import { ISchedulingItemRecord, SchedulingItemRecordStatus, SchedulingItemRecordType } from "../models/scheduling-item-record";
import * as uuid from 'uuid';
import { send } from "@giantmachines/redux-websocket";
import { IWebsocketOutMessage, OutMessageType, PushType } from "../models/websocket-out-message";
import { ITextMessage, TextMessageType } from "../models/text-message";
import { Owner, IChatMessage, IChatAttachment } from "../models/chat-message";
import { IDbMessage, IDbImageMessage } from "../models/db-message";
import { CallSubStage } from "../models/call-sub-stage";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import { UserRole } from "../models/user-role.enum";
import { IPartial } from "../models/partiial";
import { ICallHistory, CallStatus } from "../models/call-history";
import { pushAlert } from "./alert-actions";
import { RootState } from "../store";
import { dutySchedulingItemRecord, payForRecord } from "./scheduling-item-record-actions";
import { IUserToken } from "../models/user-token";
import { authFetch, lastToken } from "./auth-actions";
import { IUpload } from "../models/upload";
import { ICandidateInfo, ISdpInfo } from "../states/websocket-state";

export const CHAT_MESSAGES_LIMIT = 20;
const ICE_SERVERS = [{ urls: 'stun:stun.l.google.com:19302' }, { urls: 'stun:stun.sipnet.ru:3478' }, { urls: 'stun:stun.gmx.de:3478' }];
const sessionConstraints = {
    mandatory: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
        VoiceActivityDetection: true
    }
};

export const PREFIXED_WEBSOCKET_OPEN = 'REDUX_WEBSOCKET::OPEN';
interface OpenAction {
    type: typeof PREFIXED_WEBSOCKET_OPEN;
    payload: any;
}

export const PREFIXED_WEBSOCKET_CLOSED = 'REDUX_WEBSOCKET::CLOSED';
interface ClosedAction {
    type: typeof PREFIXED_WEBSOCKET_CLOSED;
    payload: any;
}

export const PREFIXED_WEBSOCKET_MESSAGE = 'REDUX_WEBSOCKET::MESSAGE';
interface MessageAction {
    type: typeof PREFIXED_WEBSOCKET_MESSAGE;
    payload: any;
}

export const SET_OFFER_INFO  = "SET_OFFER_INFO";
export const SET_CANDIDATE_INFO  = "SET_CANDIDATE_INFO";
interface SetOfferAction {
    type: typeof SET_OFFER_INFO;
    payload: any;
}
export const setOfferInfo: ActionCreator<SetOfferAction> = (payload: any) => {
    return {
        type: SET_OFFER_INFO,
        payload
    };
};
interface SetCandidateAction {
    type: typeof SET_CANDIDATE_INFO;
    payload: any;
}
export const setCandidateInfo: ActionCreator<SetCandidateAction> = (payload: any) => {
    return {
        type: SET_CANDIDATE_INFO,
        payload
    };
};

export const END_ALL_CALLS = 'END_ALL_CALLS';
interface EndAllCallsAction {
    type: typeof END_ALL_CALLS;
}

export const endAllCalls: ActionCreator<EndAllCallsAction> = () => {
    return {
        type: END_ALL_CALLS,
    };
};

export const CHAT_MESSAGES = 'CHAT_MESSAGES';
interface ChatMessagesAction {
    type: typeof CHAT_MESSAGES;
    messages: IChatMessage[];
    reload: boolean;
    inverse: boolean;
    lastDate: Date | undefined;
}

export const NEW_MESSAGE = 'NEW_MESSAGE';
interface NewMessageAction {
    type: typeof NEW_MESSAGE;
    message: IChatMessage;
}

export const newMessage: ActionCreator<NewMessageAction> = (message: IChatMessage) => {
    return {
        type: NEW_MESSAGE,
        message,
    };
};

export const chatMessages: ActionCreator<ChatMessagesAction> = (messages: IChatMessage[], lastDate: Date | undefined, reload: boolean = false, inverse: boolean = false) => {
    return {
        type: CHAT_MESSAGES,
        messages,
        reload,
        inverse,
        lastDate,
    };
};

export const CHAT_USER_ID = 'CHAT_USER_ID';
interface ChatUserIdAction {
    type: typeof CHAT_USER_ID;
    userId: number;
}

export const chatUserId: ActionCreator<ChatUserIdAction> = (userId: number) => {
    return {
        type: CHAT_USER_ID,
        userId,
    };
};

export const PARTIAL = 'PARTIAL';
interface ParialAction {
    type: typeof PARTIAL;
    partial: IPartial;
    remove: boolean
}

export const partial: ActionCreator<ParialAction> = (partial: IPartial, remove: boolean = false) => {
    return {
        type: PARTIAL,
        partial,
        remove
    };
};

export const PINGER = 'PINGER';
interface PingerAction {
    type: typeof PINGER;
    pinger: number;
}

export const pinger: ActionCreator<PingerAction> = (pinger: number) => {
    return {
        type: PINGER,
        pinger,
    };
};

export const PING = 'PING';
interface PingAction {
    type: typeof PING;
    ping: number;
}

export const ping: ActionCreator<PingAction> = (ping: number) => {
    return {
        type: PING,
        ping,
    };
};

export const PONG = 'PONG';
interface PongAction {
    type: typeof PONG;
}

export const pong: ActionCreator<PongAction> = () => {
    return {
        type: PONG,
    };
};

export const START_PINGING = 'START_PINGING';
interface StartPingingAction {
    type: typeof START_PINGING;
}

export const startPinging: ActionCreator<StartPingingAction> = () => {
    return {
        type: START_PINGING
    };
};

export const LATEST_INFO = 'LATEST_INFO';
interface LatestInfoAction {
    type: typeof LATEST_INFO;
    lastCallMessage: string;
    lastMessageTimestamp: Date;
}

export const latestInfo: ActionCreator<LatestInfoAction> = (lastCallMessage: string, lastMessageTimestamp: Date) => {
    return {
        type: LATEST_INFO,
        lastCallMessage,
        lastMessageTimestamp,
    };
};

export type WebsocketActionTypes = EndAllCallsAction | StartPingingAction | OpenAction | ClosedAction | ChatMessagesAction | NewMessageAction | ChatUserIdAction | PingAction | PongAction | PingerAction | ParialAction | LatestInfoAction | SetCandidateAction | SetOfferAction;

export const HANG_UP = 'HANG_UP';
interface HangUpAction {
    type: typeof HANG_UP;
}

export const _hangUp: ActionCreator<HangUpAction> = () => {
    return {
        type: HANG_UP
    };
};

export const STAGE_DATA = 'STAGE_DATA';
export interface StageDataAction {
    type: typeof STAGE_DATA;
    stageData: ICallStageData;
    subStage?: CallSubStage;
}

export const stageData: ActionCreator<StageDataAction> = (stageData: ICallStageData, subStage?: CallSubStage) => {
    return {
        type: STAGE_DATA,
        stageData,
        subStage,
    };
};

export const STAGE = 'STAGE';
export interface StageAction {
    type: typeof STAGE;
    stage: CallStage;
    stageData?: ICallStageData;
}

export const stage: ActionCreator<StageAction> = (stage: CallStage, stageData?: ICallStageData) => {
    return {
        type: STAGE,
        stage,
        stageData
    };
};

export const CONNECTION = 'CONNECTION';
interface ConnectionAction {
    type: typeof CONNECTION;
    connection: ConnectionState;
    connectionData: IConnectionData;
}

export const connection: ActionCreator<ConnectionAction> = (connection: ConnectionState, connectionData: IConnectionData) => {
    return {
        type: CONNECTION,
        connection,
        connectionData
    };
};

export const AUTO_ANSWER = 'AUTO_NSWER';
interface AutoAnswerAction {
    type: typeof AUTO_ANSWER;
    messageId: string;
    decline: boolean;
}

export const autoAnswer: ActionCreator<AutoAnswerAction> = (messageId: string, decline: boolean) => {
    return {
        type: AUTO_ANSWER,
        messageId,
        decline
    };
};

export const DISPLAY_CALL_UUID = 'DISPLAY_CALL_UUID';
interface DisplayCallUuidAction {
    type: typeof DISPLAY_CALL_UUID;
    uuid: string;
}

export const displayCallUuid: ActionCreator<DisplayCallUuidAction> = (uuid: string) => {
    return {
        type: DISPLAY_CALL_UUID,
        uuid
    };
};

export type CallActionTypes = StageDataAction | StageAction | ConnectionAction | HangUpAction | AutoAnswerAction | DisplayCallUuidAction;

const localStream = async (camera: boolean = true) => {
    const sourceInfos = await navigator.mediaDevices.enumerateDevices();

    const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: camera ? {
            facingMode: (true ? "user" : "environment")
        } : false
    });

    return stream;
}

const createMessages = (message: IDbMessage) => {
    // db(message.selfId).transaction((tx) => {
    //     tx.executeSql('INSERT INTO Messages (id, selfId, owner, userId, timestamp, text, deleted, toServer) VALUES (?, ?, ?, ?, ?, ?, ?, ?);', 
    //     [message.id, message.selfId, message.owner, message.userId, message.timestamp, message.text, message.deleted || 0, message.toServer || 0]);

    // });
}

const createImageMessages = (message: IDbImageMessage, selfId: number) => {
    console.warn('createImageMessages', message);
    // db(selfId).transaction((tx) => {
    //     tx.executeSql('INSERT INTO ChatImages (link, messageId, galeryLink, ownerLink, contentType) VALUES (?, ?, ?, ?, ?);', 
    //     [message.link, message.messageId, message.galeryLink, message.ownerLink, message.contentType]);
    // });
}

export const sendMessage = (
    text: ITextMessage,
    record: ISchedulingItemRecord,
    pushType: PushType = PushType.None): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {

        const owner = getState().auth.userToken!.selfId;
        const id = uuid.v1();
        const timestamp = new Date();

        const msg: IChatMessage = {
            id,
            message: JSON.stringify(text),
            textMessage: text,
            doctorId: record.doctorId!,
            patientId: record.patientId!,
            owner: record.doctorId === owner ? Owner.Doctor : Owner.Patient,
            timestamp,
        }

        // await AsyncStorage.setItem(id + 'record', JSON.stringify(record));
        //createMessages(msg);
        dispatch(newMessage(msg));

        await _sendMessage(text, record, getState, dispatch, 'chat', undefined, pushType, id, timestamp);
    }

const _sendMessageWithAttachments = async (
    text: ITextMessage,
    attachments: IChatAttachment[],
    record: ISchedulingItemRecord,
    getState: () => RootState,
    dispatch: (v: any) => void,
    callHistory: ICallHistory | undefined = undefined,
    pushType: PushType = PushType.None,
    id: string = uuid.v1(),
    timestamp: Date = new Date(),
    api: 'initiate' | 'chat') => {
    // TODO: cache record
    // console.warn("record", record);

    const message: IWebsocketOutMessage = {
        type: OutMessageType.Message,
        message: {
            id,
            message: JSON.stringify(text),
            doctorId: record.doctorId!,
            patientId: record.patientId!,
            timestamp,
        },
        authorization: (await lastToken(dispatch))?.auth || '',
        browser: true,
        pushType,
        callHistory: callHistory,
    };

    if (attachments.length > 0) {
        console.warn("partial");
        message.message!.attachments = [];
        for (const attachment of attachments) {
            message.message?.attachments.push(attachment);
        }

        const s = JSON.stringify(message);
        let i = 0;
        const ss = s.substring(i, Math.min(s.length - 1, i + 16536));
        i += 16536;
        const p: IWebsocketOutMessage = {
            type: OutMessageType.Partial,
            partialPart: ss,
            partialEnd: i >= s.length,
            partialId: uuid.v1(),
            authorization: (await lastToken(dispatch))?.auth || '',
            browser: true,
        };
        console.warn("total size", i);
        if (!p.partialEnd) {
            dispatch(partial({ k: p.partialId, i, s, d: new Date().getTime() } as IPartial));
        }
        dispatch(send(p));
    } else {
        console.warn("full");
        console.warn("message", message);
        dispatch(send(message));
        return message.message?.id;
    }
}

export const busy = (
    status: CallStatus, record: ISchedulingItemRecord, callUuid: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const callHistory: ICallHistory | undefined = { callId: callUuid, recordId: record.id!, status };
    const text: ITextMessage = { data: callUuid, type: TextMessageType.Busy };
    await _sendMessage(text, record, getState, dispatch, 'chat', callHistory);
}

const _sendMessage = async (
    text: ITextMessage,
    record: ISchedulingItemRecord,
    getState: () => RootState,
    dispatch: (v: any) => void,
    api: 'initiate' | 'chat',
    callHistory: ICallHistory | undefined = undefined,
    pushType: PushType = PushType.None,
    id: string = uuid.v1(),
    timestamp: Date = new Date()) => {
    return _sendMessageWithAttachments(text, [], record, getState, dispatch, callHistory, pushType, id, timestamp, api);
}

export const ringOnScheduler = (
    record: ISchedulingItemRecord,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    try {
        dispatch(loadingStart(LoadingSubject.InitiateCall));

        const state = getState();
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
        'external/private/call/record', 'POST',
            { id: record.id }, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        console.log('notifClickedResponse', response.status);
        if (response.status !== 200) {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            dispatch(loadingEnd(LoadingSubject.InitiateCall));
            return;
        }

        record = await response.json();
        await ring(state.call.connectionData, record, getState, dispatch);
    } catch {
        dispatch(loadingEnd(LoadingSubject.InitiateCall));
    }
}


export const ringOnDuty = (
    schedulingItemId: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    try {
        dispatch(loadingStart(LoadingSubject.InitiateCall));
        const state = getState();

        let record = await dutySchedulingItemRecord({
            schedulingItemId: schedulingItemId,
            status: SchedulingItemRecordStatus.Created,
            type: SchedulingItemRecordType.Duty
        }, dispatch, getState);

        if (!record) {
            dispatch(loadingEnd(LoadingSubject.InitiateCall));
            dispatch(pushAlert('Unfortunately, the doctor is currently not available for call or chat, please try again later'));
            return;
        }

        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
        'external/private/call/record', 'POST',
            { id: record.id }, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        console.log('notifClickedResponse', response.status);
        if (response.status !== 200) {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            dispatch(loadingEnd(LoadingSubject.InitiateCall));
            return;
        }

        record = await response.json();

        if (record?.price) {
            dispatch(payForRecord(record));
            return record;
        }

        await ring(state.call.connectionData, record!, getState, dispatch);
    } catch {
        dispatch(loadingEnd(LoadingSubject.InitiateCall));
    }
}

const ring = async (
    connectionData: IConnectionData,
    record: ISchedulingItemRecord,
    getState: () => RootState,
    dispatch: any
) => {
    try {
        if (!(record.status === SchedulingItemRecordStatus.Ready ||
            record.status === SchedulingItemRecordStatus.Initiated ||
            record.status === SchedulingItemRecordStatus.Accepted)) {
            dispatch(pushAlert('Available only during appointment'));
            dispatch(loadingEnd(LoadingSubject.InitiateCall));
            return;
        }

        console.log('peer', connectionData.peerConnection);
        if (connectionData.peerConnection) {
            connectionData.peerConnection.close();
        }

        const selfId: any = getState().auth.userToken?.selfId;

        const id = uuid.v1();
        const callHistory: ICallHistory = { callId: id, recordId: record.id!, status: CallStatus.Initiated };

        //rinq
        const peerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });
        console.log('ring_peerConnection', JSON.stringify(peerConnection));

        const locStream = await localStream();
        console.log('ring_locStream', JSON.stringify(locStream));

        locStream.getTracks().forEach((track) => {
            peerConnection.addTrack(track, locStream);
        });

        console.log('ring_getTracks_peerConnection', JSON.stringify(peerConnection));

        peerConnection.onicecandidate = async (event) => {
            if (event.candidate) {
                if (event.candidate) {
                    const candidateInfo: ICandidateInfo = {
                        data: {
                            type: "OFFER",
                            candidate: event.candidate.toJSON(),
                            callUuid: id,
                            patientUuid: selfId,
                        },
                        type: TextMessageType.Candidate,
                    }
                    console.log('ring_onicecandidate_candidateInfo', JSON.stringify(candidateInfo))
                    await _sendMessage(
                        candidateInfo,
                        record,
                        getState,
                        dispatch,
                        "chat",
                        callHistory,
                        PushType.None,
                        uuid.v1() as string
                    );
                };
            }
        }

        peerConnection.onconnectionstatechange = (e) => {
            console.log('Connection state:', peerConnection.connectionState);
            if (peerConnection.connectionState === 'disconnected' || peerConnection.connectionState === 'closed' || peerConnection.connectionState === 'failed') {
                dispatch(_hangUp());
            }
        };

        const remoteStream = new MediaStream([]);
        peerConnection.ontrack = (event) => {
            event.streams[0].getTracks().forEach((track: any) => {
                remoteStream.addTrack(track);
            });
            console.log("ring_ontrack_remoteStream", remoteStream);
            dispatch(stageData({ peerStreamURL: remoteStream }));
        };

        const offer = await peerConnection.createOffer();
        console.log('ring_offer', JSON.stringify(offer));

        await peerConnection.setLocalDescription(offer);
        console.log('ring_peerConnection_setLocalDescription', JSON.stringify(peerConnection));

        const offerData: ISdpInfo = {
            data: {
                type: "OFFER",
                // @ts-ignore
                sdp: offer.sdp,
                callUuid: id,
                patientUuid: selfId,
            },
            type: TextMessageType.sdpInfo,
        }

        await _sendMessage(
            // @ts-ignore
            offerData,
            record,
            getState,
            dispatch,
            "chat",
            callHistory,
            PushType.None,
            uuid.v1() as string
        );
        //rinq

        const text: ITextMessage = {
            data: { patientUuid: selfId, record },
            type: TextMessageType.Call
        };
        await _sendMessage(
            text,
            record,
            getState,
            dispatch,
            'chat',
            callHistory,
            PushType.Voip,
            id
        );

        dispatch(
            stage(
                CallStage.Outcome, {
                selfStream: locStream,
                selfStreamURL: (locStream as MediaStream),
                record, callUuid: id
            })
        );
        dispatch(connection(ConnectionState.Registered, { peerConnection }));
    } catch (error) {
        console.error("Error during call initiation:", error);
        dispatch(pushAlert("Call initiation failed. Please try again."));
    } finally {
        dispatch(loadingEnd(LoadingSubject.InitiateCall));
    }
}

export const waitAndRing = (
    recordId: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    try {
        dispatch(loadingStart(LoadingSubject.InitiateCall));
        const state = getState();

        const started = new Date().getTime();

        do {
            const response = await authFetch(
                getState, dispatch, environment.baseUrl +
            'external/private/call/record', 'POST',
                { id: recordId }, { Accept: 'application/json', 'Content-Type': 'application/json' }
            );

            console.log('notifClickedResponse', response.status);
            if (response.status !== 200) {
                dispatch(pushAlert('Unknown error. Please, check internet connection'));
                dispatch(loadingEnd(LoadingSubject.InitiateCall));
                return;
            }

            const record: ISchedulingItemRecord = await response.json();

            if (record.status !== SchedulingItemRecordStatus.Ready) {
                continue;
            }

            await ring(state.call.connectionData, record!, getState, dispatch);
            return;
        } while (new Date().getTime() - started < 60000);

        dispatch(pushAlert('Unknown error. Please, check internet connection'));
        dispatch(loadingEnd(LoadingSubject.InitiateCall));
    } catch {
        dispatch(loadingEnd(LoadingSubject.InitiateCall));
    }
}

export const answer3 = (
    connectionData: IConnectionData, data: ICallStageData
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    // if (!connectionData.peerconnection || connectionData.peerconnection.connectionState === 'disconnected' || connectionData.peerconnection.connectionState === 'closed' || connectionData.peerconnection.connectionState === 'failed') {
    //     return;
    // }
    // const call = connectionData.peer.call(data.peerUuid, data.selfStreamURL);
    // console.log('startCall', data.callUuid);
    // // !useSimpleUI && RNCallKeep.startCall(data.callUuid!, data.record!.doctorId!.toString(), data.record!.doctorName || data.record!.doctorId?.toString(), 'generic', true);
    // // InCallManager.setForceSpeakerphoneOn(true);
    // console.warn('call', data.peerUuid);
    // call.on('stream', function (stream: any) {
    //     console.warn('remote answer3', stream);
    //     // Platform.OS === 'android' && !useSimpleUI && RNCallKeep.setCurrentCallActive(data.callUuid!);
    //     dispatch(stageData({ peerStreamURL: stream }));
    // });
    // call.on('close', function () {
    //     console.log('close answer3', data.callUuid!);
    //     // if (useSimpleUI) {
    //     dispatch(endAllCalls());
    //     // dispatch(_hangUp());
    //     // return;
    //     // }
    //     // RNCallKeep.endCall(data.callUuid!);
    //     dispatch(_hangUp());
    // });
    // dispatch(stageData({ peerUuid2: null, call }));
}

export const answer2 = (
    stageData: ICallStageData
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    // console.log('answer2', stageData.selfStream);
    //stageData.call.answer(stageData.selfStream);
}

export const answer = (
    camera: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {

    const state = getState();

    const connectionData = state.call.connectionData;
    const record = state.call.stageData?.record;
    const callUuid = state.call.stageData?.callUuid;
    const selfId: any = state.auth.userToken?.selfId;
    const {offerInfo, candidateInfo} = state.websocket;

    console.log('answerInfos', {offerInfo, candidateInfo});
    if (!offerInfo || !candidateInfo) {
        return;
    };

    try {
        dispatch(loadingStart(LoadingSubject.InitiateCall));

        if (!record || !callUuid) {
            dispatch(hangUp());
            dispatch(loadingEnd(LoadingSubject.InitiateCall));
            return;
        }

        if (connectionData.peerconnection) {
            connectionData.peerconnection.close();
        }

        const callHistory: ICallHistory = { callId: callUuid, recordId: record.id!, status: CallStatus.Answered, device: 'BROWSER' };
        
        //ansv
        const peerConnection: any = new RTCPeerConnection({ iceServers: ICE_SERVERS });
        console.log('answer_peerConnection', JSON.stringify(peerConnection));

        const locStream = await localStream(camera);
        console.log('answer_locStream', JSON.stringify(locStream));

        locStream.getTracks().forEach((track) => {
            console.log('answer_getTracks_addTrack_track', track);
            peerConnection.addTrack(track, locStream);
        });
        console.log('answer_getTracks_peerConnection', JSON.stringify(peerConnection));

        const remoteStream = new MediaStream([]);
        peerConnection.ontrack = (event: any) => {
            if (event.streams.length > 0) {
              event.streams[0].getTracks().forEach((track: any) => {
                remoteStream.addTrack(track);
              });
            }
            console.log('answer_ontrack_remoteStream', remoteStream);
            dispatch(stageData({ peerStreamURL: remoteStream }));
        };

        peerConnection.onicecandidate = async (event) => {
            if (event.candidate) {
                const candidateInfo: ICandidateInfo = {
                    data: {
                        type: "ANSWER",
                        candidate: event.candidate.toJSON(),
                        callUuid: callUuid,
                        doctorUuid: selfId,
                    },
                    type: TextMessageType.Candidate,
                }
                console.log('answer_onicecandidate_candidateInfo', JSON.stringify(candidateInfo))
                await _sendMessage(
                    candidateInfo,
                    record,
                    getState,
                    dispatch,
                    "chat",
                    callHistory,
                    PushType.None,
                    uuid.v1() as string
                );
            }
        };

        peerConnection.onconnectionstatechange = (e) => {
            console.log('Connection state:', peerConnection.connectionState);
            if (peerConnection.connectionState === 'closed' || peerConnection.connectionState === 'failed') {
                dispatch(_hangUp());
            }
        };

        await peerConnection.setRemoteDescription(new RTCSessionDescription({
            //@ts-ignore
            type: 'offer',
            //@ts-ignore
            sdp: offerInfo.sdp
        }));

        const answer = await peerConnection.createAnswer(sessionConstraints);
        await peerConnection.setLocalDescription(answer);
        console.log('answer_peerConnection_setLocalDescription', JSON.stringify(answer));

        const answerData: ISdpInfo = {
            data: {
                type: "ANSWER",
                sdp: answer.sdp,
                callUuid: callUuid,
                doctorUuid: selfId,
            },
            type: TextMessageType.sdpInfo,
        }
        console.log('answer_answer_data', answerData);

        await _sendMessage(
            // @ts-ignore
            answerData,
            record,
            getState,
            dispatch,
            "chat",
            callHistory,
            PushType.None,
            uuid.v1() as string
        );

        candidateInfo.forEach((c: any, i: any)=> {
            peerConnection.addIceCandidate(new RTCIceCandidate(c.candidate))
            console.log(`answer_peerConnection_candidate${i}`, JSON.stringify(c.candidate));
        })
        console.log(`answer_peerConnection_addIceCandidate`, JSON.stringify(peerConnection));
        //ansv

        const text: ITextMessage = {
            data: { doctorUuid: record.doctorId as unknown as string, callUuid },
            type: TextMessageType.Response
        };
        const b = await _sendMessage(
            text,
            record,
            getState,
            dispatch,
            'chat',
            callHistory
        );
        if (!b) {
            dispatch(loadingEnd(LoadingSubject.InitiateCall));
            return;
        }

        dispatch(connection(ConnectionState.Registered, { peerConnection }));

        dispatch(stage(CallStage.Call, { selfStream: locStream, selfStreamURL: locStream }));
        dispatch(loadingEnd(LoadingSubject.InitiateCall));

                // peer.on('call', function (call: any) {

                //     console.warn('call.peer ' + call.peer);
                //     // TODO: check call.peer
                //     dispatch(stageData({ call, peerUuid2: call.peer, response: true }, CallSubStage.IncomeReady))

                //     call.on('stream', function (stream: any) {
                //         // Platform.OS === 'android' && !useSimpleUI && RNCallKeep.setCurrentCallActive(callUuid);
                //         dispatch(stageData({ peerStreamURL: stream }));
                //     });
                //     call.on('close', function () {
                //         console.log('close answer', callUuid);
                //         // if (useSimpleUI) {
                //         dispatch(endAllCalls());
                //         // dispatch(_hangUp());
                //         // return;
                //         // }
                //         // RNCallKeep.endCall(callUuid);
                //         dispatch(_hangUp());
                //     });

                //     call.answer(getState().call.stageData!.selfStreamURL);
                // });

    } catch (error) {
        console.error("Error during call answer:", error);
        dispatch(loadingEnd(LoadingSubject.InitiateCall));
        dispatch(pushAlert("Call answer failed. Please try again."));
    } finally {
        dispatch(setCandidateInfo([]));
    }
}

export const hangUp = (
    status?: CallStatus
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const state = getState();
    const stageData = state.call.stageData;
    if (!status) {
        status = state.call.stage === CallStage.Call ? CallStatus.Done : CallStatus.Canceled;
    }

    console.log('stageData.call', stageData);
    if (stageData && stageData.call && stageData.call.open) {
        stageData.call.close();
    } else {
        console.log('close hangUp');
        dispatch(_hangUp());
    }
    if (stageData?.record) {
        //dispatch(busy(stageData.record, stageData, token));
        const record = stageData.record;
        const callHistory: ICallHistory | undefined = stageData.callUuid && status ? { callId: stageData.callUuid, recordId: stageData.record.id!, status } : undefined;
        console.log('ICallHistory', callHistory);
        const text: ITextMessage = { data: stageData.callUuid!, type: TextMessageType.Busy };
        await _sendMessage(text, record, getState, dispatch, 'chat', callHistory);
    }
};

export const replaceTrack = (
    stageData: ICallStageData
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    if (!stageData.selfStreamURL
        || !stageData.selfStreamURL.getVideoTracks()
        || stageData.selfStreamURL.getVideoTracks().length === 0) {
        return;
    }
    // (stageData.selfStreamURL.getVideoTracks()[0] as any)._switchCamera();
};

export const toggleSpeaker = (
    data: ICallStageData
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const speakerOn = !data.speakerOn;
    console.log('speakerOn', speakerOn);
    // InCallManager.setForceSpeakerphoneOn(speakerOn);
    dispatch(stageData({ speakerOn }));
};

export const muteTrack = (
    video: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const data = getState().call.stageData;
    if (video) {
        if (!data?.selfStreamURL
            || !data.selfStreamURL.getVideoTracks()
            || data.selfStreamURL.getVideoTracks().length === 0) {
            return;
        }
        dispatch(stageData({ mutedVideo: !(data.selfStreamURL.getVideoTracks()[0].enabled = !!data.mutedVideo) }));
    } else {
        if (!data?.selfStreamURL
            || !data.selfStreamURL.getAudioTracks()
            || data.selfStreamURL.getAudioTracks().length === 0) {
            return;
        }
        const muted = !(data.selfStreamURL.getAudioTracks()[0].enabled = !!data.mutedAudio);
        console.log('muteTrack', muted);
        dispatch(stageData({ mutedAudio: muted }));
        if (data.callUuid) {
            // if (useSimpleUI) {
            //     return;
            // }
            // RNCallKeep.setMutedCall(data.callUuid, muted);
        }
    }
};

export const processChatAuth = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const userToken = getState().auth.userToken;
    console.log('processChatAuth.az.ezgil.videodoctor.lastMessageTimestamp.v2.' + userToken);
    console.warn("lastMessageTimestamp", getState().websocket.lastMessageTimestamp);
    const message: IWebsocketOutMessage = {
        authorization: (await lastToken(dispatch))?.auth || '',
        browser: true,
        type: OutMessageType.Auth,
        lastMessageTimestamp: getState().websocket.lastMessageTimestamp
    };
    dispatch(send(message));
    // TODO: send undelivered messages
    //console.warn("Auth sent", message);
}

export const processAttachments = async (message: IChatMessage, getState: () => RootState, dispatch: (a: any) => void) => {
    if (!message.attachments) {
        return;
    }
    for (const attachment of message.attachments) {
        console.warn('attachment', attachment);
        const response = await authFetch(getState, dispatch, environment.baseWs +
            `api/Chat/file/${message.id}/${message.doctorId === getState().auth.userToken?.selfId ? attachment.linkDoctor : attachment.linkPatient}`, 'GET');

        if (response.status === 200) {
            attachment.content = await response.text();
        }
    }
}

const loadChatMessages = async (
    getState: () => RootState, dispatch: (a: any) => void,
    userId: number, total: number, to?: Date
) => {
    const response = await authFetch(getState, dispatch, environment.baseWs +
        'api/Chat/history',
        'POST', { to, participantId: userId, limit: CHAT_MESSAGES_LIMIT });

    console.log('response', response);

    if (response.status === 200) {
        const messages = await response.json() as IChatMessage[];
        for (const m of messages) {
            m.textMessage = JSON.parse(m.message);
            await processAttachments(m, getState, dispatch);
        }
        console.log('messages', messages);

        const loaded = messages.filter(m => m.textMessage?.type === TextMessageType.Text);
        dispatch(chatMessages(loaded, messages.length > 0 ? messages[messages.length - 1].created : undefined, !to, true));
        total = total + loaded.length;
        return { to: total < CHAT_MESSAGES_LIMIT / 2 && messages.length === CHAT_MESSAGES_LIMIT ? messages[messages.length - 1].created : null, total };
    }
    dispatch(pushAlert('Messages loading error'));
    return null;
}

export const loadChat = (
    userId: number, to?: Date | null
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    console.log('chatUserId: ' + userId + ', to: ' + to);

    const subj = !to ? LoadingSubject.MessagesFull : LoadingSubject.Messages;
    try {
        dispatch(loadingStart(subj));
        dispatch(chatUserId(userId));

        let p: { to?: Date | null, total: number } | null = { to, total: 0 };

        while (p !== null && p.to !== null) {
            p = await loadChatMessages(getState, dispatch, userId, p.total, p.to);
            console.log('to', to);
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(subj));
    }
}

export const sendFiles = (
    files: IUpload[],
    record: ISchedulingItemRecord,
    pushType: PushType = PushType.None,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    console.warn("sendFiles1", files);
    const owner = getState().auth.userToken!.selfId;
    for (const file of files) {
        const content = file.base64.substring(file.base64.indexOf('base64,') + 7);
        //console.warn("content", content);
        const text: ITextMessage = { data: "", type: TextMessageType.Text };

        const id = uuid.v1();
        const timestamp = new Date();
        const msg: IDbMessage = {
            id,
            selfId: owner,
            userId: owner === record?.doctorId ? record?.patientId! : record?.doctorId!,
            text: "",
            timestamp: timestamp.toISOString(),
            owner: owner,
            toServer: -record.id!,
        }

        const attachments = [{
            content,
            mimeType: file.type,
        }];
        const message: IChatMessage = {
            id,
            message: '',
            doctorId: record.doctorId!,
            patientId: record.patientId!,
            timestamp,
            attachments,
        };

        createMessages(msg);
        //createImageMessages(msgImage, msg.selfId);
        dispatch(newMessage(message));
        // TODO: convert extension to mime type
        await _sendMessageWithAttachments(text, attachments, record, getState, dispatch, undefined, pushType, id, timestamp, 'chat');
    }
    console.warn("sendFiles", files);
}
