import { IWebsocketState } from "../states/websocket-state";
import { AuthActionTypes } from "../actions/auth-actions";
import {
    WebsocketActionTypes, PREFIXED_WEBSOCKET_OPEN, NEW_MESSAGE, CHAT_MESSAGES,
    PREFIXED_WEBSOCKET_CLOSED, CHAT_USER_ID, PING, PONG, PARTIAL, PINGER, LATEST_INFO, CHAT_MESSAGES_LIMIT,
    SET_OFFER_INFO,
    SET_CANDIDATE_INFO
} from "../actions/call-actions";
import { IChatMessage } from "../models/chat-message";

const initialState: IWebsocketState = {
    online: false,
    messages: [],
    lastDate: new Date(),
    loadEarlier: false,
    partials: {},
    lastMessageTimestamp: new Date(),

    offerInfo: undefined,
    candidateInfo: [],
};

export function websocketReducer(state: IWebsocketState = initialState, action: WebsocketActionTypes | AuthActionTypes) {
    switch (action.type) {
        case LATEST_INFO:
            return { ...state, lastCallMessage: action.lastCallMessage, lastMessageTimestamp: action.lastMessageTimestamp };
        case PARTIAL:
            const partials = state.partials;
            if (action.remove) {
                delete partials[action.partial.k];
            } else {
                partials[action.partial.k] = action.partial;
            }
            return { ...state, ...{ partials: { ...partials } } };
        case CHAT_USER_ID:
            return { ...state, ...{ chatUserId: action.userId } };
        // 
        case SET_OFFER_INFO:
            console.log("setting_offer_info", action.payload);
            return { ...state, ...{ offerInfo: action.payload } };
        case SET_CANDIDATE_INFO:
            console.log("setting_candidate_info", action.payload);
            return { ...state, ...{ candidateInfo: action.payload } };
        // 
        case NEW_MESSAGE:
            console.log("NEW_MESSAGE", action.message);
            return { ...state, ...{ messages: [action.message].concat(state.messages || []) } };
        case PREFIXED_WEBSOCKET_OPEN:
            console.log("WEBSOCKET_OPEN");
            return { ...state, ...{ online: true } };
        case PREFIXED_WEBSOCKET_CLOSED:
            console.log("WEBSOCKET_CLOSED", action.payload);
            return { ...state, ...{ online: false } };
        case CHAT_MESSAGES:
            const msgs = action.messages;
            const lastDate = action.lastDate || state.lastDate || new Date();
            console.log("msgs", msgs);
            if (state.messages.length !== 0 && !action.reload) {
                const map: { [key: string]: IChatMessage | false } = {};
                for (const msg of msgs) {
                    map[msg.id] = msg;
                }
                const messages: IChatMessage[] = [];
                for (const message of state.messages) {
                    if (map[message.id]) {
                        messages.push({ ...message, sent: true });
                        map[message.id] = false;
                    } else {
                        messages.push(message);
                    }
                }
                const rest: IChatMessage[] = msgs.filter(m => map[m.id]);
                if (rest.length === 0) {
                    return {
                        ...state, messages,
                        loadEarlier: action.messages.length > 0,
                        lastDate,
                    }
                }
                //console.warn(rest);
                return {
                    ...state,
                    messages: action.inverse ? messages.concat(rest) : rest.concat(messages),
                    loadEarlier: action.messages.length > 0,
                    lastDate,
                };
            } else {
                return {
                    ...state,
                    messages: msgs,
                    loadEarlier: action.messages.length > 0,
                    lastDate,
                };
            }
        case PING:
            console.log(PING);
            state.ping && clearTimeout(state.ping);
            return {
                ...state,
                ping: action.ping,
            };
        case PINGER:
            console.log(PINGER);
            state.pinger && clearTimeout(state.pinger);
            return {
                ...state,
                pinger: action.pinger,
            };
        case PONG:
            console.log(PONG);
            state.ping && clearTimeout(state.ping);
            return { ...state, ping: null };
        default:
            return state;
    }
}
